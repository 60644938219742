a, .interaction-element { /* For links and other interactions */
  color: var(--interaction-color);
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

a:hover, .interaction-element:hover {
  color: var(--lighten-hover-color);
  transition: color 0.3s ease-in-out;
}

.contact-form button, .some-button-class {
  background-color: var(--interaction-color);
  color: #ffffff;
}

.contact-form button:hover, .some-button-class:hover {
  background-color: darken(var(--interaction-color), 10%);
}
