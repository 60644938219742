/* Container styles */
.resume-page {
    max-width: 900px;
    margin: var(--box-margin);
    padding: 0 30px;
    background-color: var(--main-bg-color);
    color: #ffffff;
    border-radius: 10px;
    box-shadow: var(--box-shadow);
    font-family: 'Fira Code', monospace;
    box-sizing: border-box; /* Ensure padding and border are included in width calculations */
}

/* Section styles */
.resume-section {
    margin-bottom: 30px;
}

/* Section headers */
.resume-section h3 {
    font-size: var(--font-size-h3);
    color: #ffffff;
    margin-top: 30px;
    text-align: center;
    border-bottom: 1px solid #333;
    padding-bottom: 10px;
}

.resume-item h4 {
    font-size: var(--font-size-h4);
    margin-bottom: 5px;
    color: #ffffff;
}

/* Item styles */
.resume-item {
    margin-bottom: 20px;
    padding: 15px;
    background-color: var(--header-footer-bar-color);
    border-radius: 8px;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
}

.resume-item span {
    color: #d4d4d4;
}

.resume-date {
    font-size: clamp(.85rem, 2.5vw, 1rem);
    color: #b0b0b0;
    margin-bottom: 10px;
}

/* Skills Section */
.skills-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.skill-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.skill-name {
    font-size: clamp(.9rem, 2.5vw, 1rem);
    margin-bottom: 5px;
    color: #d4d4d4;
}

.progress-bar-container {
    width: 100%;
    max-width: 100%; /* Ensure it doesn’t overflow */
    background-color: #333333;
    height: 10px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
}

.progress-bar {
    height: 100%;
    width: 0%; /* Initial width is 0% */
    background-color: var(--interaction-color);
    border-radius: 5px;
    position: relative;
    transition: width 0.5s ease-out; /* Smooth transition */
}

/* Show percentage inside the progress bar */
.progress-percent {
    position: absolute;
    left: 50%; /* Center horizontally */
    top: -20px; /* Slightly above the progress bar */
    transform: translateX(-50%); /* Adjust for exact centering */
    color: white;
    font-size: clamp(.8rem, 2vw, 0.9rem);
    font-weight: bold;
}

/* Responsive design */
@media (max-width: 768px) {
    .resume-page {
        padding: 20px;
    }
}

@media (max-width: 480px) {
    .progress-bar-container {
        max-width: 90%; /* Ensure it fits smaller screens */
    }
}
