/* Ensure all elements follow box-sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

.contact-page {
  max-width: 900px;
  margin: var(--box-margin);
  padding: 0 30px 30px 30px;
  background-color: var(--main-bg-color);
  color: #ffffff;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  font-family: 'Fira Code', monospace;
}

.contact-page h2 {
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
  font-size: var(--font-size-h2);
  /* Responsive font size */
}

.contact-form {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 30px;
  /* Provide space for the message */
}

.contact-form label {
  margin-bottom: 15px;
  font-size: clamp(0.9rem, 2vw, 1.3rem);
  /* Responsive font size */
  color: #ffffff;
  width: 100%;
  /* Ensures labels and fields are the same width */
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 12px;
  margin-top: 8px;
  background-color: var(--header-footer-bar-color);
  border: 1px solid #333;
  border-radius: 5px;
  color: var(--text-color);
  font-size: clamp(0.9rem, 2vw, 1rem);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, .5);;
}

.contact-form textarea {
  min-height: 120px;
  resize: vertical;
}

.contact-form button {
  width: 100%;
  /* Matches button width with input fields */
  padding: 12px;
  margin-top: 20px;
  background-color: var(--interaction-color);
  /* Using theme color */
  border: none;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  font-size: clamp(0.9rem, 2vw, 1rem);
  /* Responsive font size */
  font-weight: bold;
  text-align: center;
  box-shadow: var(--box-shadow);
  /* Adds a box-shadow to the button */
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: var(--hover-theme-color);
  /* Hover color */
}

.contact-intro {
  text-align: center;
  color: #cccccc;
  font-size: clamp(0.9rem, 2vw, 1.3rem);
  /* Responsive font size */
  margin-bottom: 25px;
  max-width: 600px;
  margin: 0 auto 20px;
}

/* Style for the error/success message container */
.form-message {
  margin-top: 20px;
  /* Add space between the form and the message */
  padding: 15px;
  border-radius: 5px;
  text-align: center;
  width: 100%;
  font-size: clamp(0.9rem, 2vw, 1rem);
  /* Responsive font size */
  box-sizing: border-box;
  display: block;
}

.recaptcha-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.success {
  background-color: #2d2d2d;
  color: white;
}

.warning {
  background-color: #2d2d2d;
  color: red;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .contact-page {
    padding: 20px;
    width: 90%;
  }

  .contact-form {
    gap: 12px;
  }

  .contact-form button {
    padding: 10px;
  }

  .recaptcha-container {
    width: 100%; /* Makes sure it stretches to full container width */
    display: flex;
    justify-content: center; /* Horizontally center */
  }

  .recaptcha-wrapper {
    transform: scale(0.9);
    transform-origin: center center;
    width: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

@media (max-width: 480px) {
  .contact-page {
    padding: 15px;
    width: 100%;
  }

  .contact-form {
    gap: 10px;
  }

  .contact-form input,
  .contact-form textarea {
    padding: 10px;
  }

  .contact-form button {
    padding: 10px;
  }

  .recaptcha-container {
    width: 100%; /* Makes sure it stretches to full container width */
    display: flex;
    justify-content: center; /* Horizontally center */
  }

  .recaptcha-wrapper {
    transform: scale(0.75);
    transform-origin: center center;
    width: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

@media (max-width: 360px) {
  .recaptcha-container {
    width: 100%; /* Makes sure it stretches to full container width */
    display: flex;
    justify-content: center; /* Horizontally center */
  }

  .recaptcha-wrapper {
    transform: scale(0.65);
    transform-origin: center center;
    width: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
