/* Basic Layout and Styling */
.home-page {
  max-width: 900px;
  margin: var(--box-margin);
  padding: 0 30px;
  background-color: var(--main-bg-color);
  color: #ffffff;
  font-family: 'Fira Code', monospace;
  box-shadow: var(--box-shadow);
  box-sizing: border-box;
  /* Include padding and border in the width calculation */
}

/* Introduction Section */
.intro-section {
  text-align: center;
  margin-bottom: 30px;
}

.intro-section h1 {
  font-size: var(--font-size-h1);
  color: #ffffff;
  margin-bottom: 5px;
}

.tagline {
  font-size: clamp(1rem, 2.5vw, 1.4rem);
  /* Responsive font size between 1rem and 1.2rem */
  color: #a6a6a6;
  margin-bottom: 10px;
}

.intro-description {
  font-size: clamp(0.9rem, 2vw, 1.2rem);
  /* Responsive font size between 0.95rem and 1rem */
  color: #b3b3b3;
  margin: 15px auto;
  line-height: 1.6;
}

/* Section Title Styling */
.section-title {
  font-size: var(--font-size-h3);
  /* Responsive font size between 1.3rem and 1.5rem */
  color: #ffffff;
  margin-top: 30px;
  text-align: center;
  border-bottom: 1px solid #333;
  padding-bottom: 10px;
}

.ide-container pre {
  margin: 0;
  font-family: 'Fira Code', monospace;
  font-size: var(--font-size-pre);
  /* Readable font size for code */
  color: #d4d4d4;
  white-space: pre-wrap;
  /* Ensure long code lines wrap */
  word-wrap: break-word;
  /* Prevent long lines from overflowing */
  line-height: 1.4;
  /* Space between code lines */
}


.project-card, .ide-container{
  background-color: var(--header-footer-bar-color);
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
  color: #e0e0e0;
  margin-bottom: 15px;
}

.project-card h4 {
  font-size: var(--font-size-h4);
  color: #ffffff;
  margin: 0 0 5px;
}

.project-card p {
  font-size: var(--font-size-p);
  /* Responsive font size between 0.9rem and 0.95rem */
  color: #cccccc;
  line-height: 1.4;
}

/* Project Links */
.project-link {
  text-decoration: none;
  color: var(--interaction-color);
  /* Light blue */
  font-weight: bold;
  transition: color 0.3s ease-in-out;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  /* Space between text and icon */
}


/* Font Awesome Open Icon Styling */
.open-icon {
  font-size: 0.9rem;
  color: var(--interaction-color);
  transition: color 0.3s ease-in-out;
  transform: translateY(1px); /* Slightly adjust the icon position */
}

.project-link:hover, .project-link:hover .open-icon {
  color: var(--lighten-hover-color);
}

/* Social Icons */
.social-icons {
  display: flex;
  justify-content: center;
  gap: 25px;
  margin: 50px;
}

.social-icon {
  font-size: clamp(1.6rem, 3.5vw, 2.2rem);
  /* Responsive font size between 1.5rem and 2rem */
  color: #ffffff;
  transition: transform 0.3s ease, color 0.3s ease;
}

.social-icon:hover {
  transform: scale(1.1);
}

.social-icon.linkedin:hover {
  color: #0a66c2;
}

.social-icon.xtwitter:hover {
  color: #1da1f2;
}

.social-icon.github:hover {
  color: #333;
}

.social-icon.facebook:hover {
  color: #1877f2;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .home-page {
    width: 100%;
    /* For mobile, ensure it spans full width */
    padding: 20px;
    /* Adjust padding */
  }

  .ide-container {
    padding: 15px;
    /* Compact padding for smaller devices */
  }

  /* .projects-section {
  } */

  .project-card {
    padding: 15px;
    /* Slightly smaller padding */
  }

  .social-icons {
    margin-top: 40px;
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .home-page {
    padding: 15px;
    /* Compact padding */
  }

  .projects-section {
    max-width: 100%;
    /* Full width for very small screens */
  }

  .project-card {
    padding: 10px;
    /* Compact padding */
    border-radius: 6px;
    /* Smaller border-radius */
  }
  .project-link {
    gap: 4px;
    /* Reduce space between text and icon */
  }

  .open-icon {
    font-size: 0.8rem;
    /* Slightly smaller for smaller screens */
  }

  .ide-container {
    padding: 10px;
    /* Compact padding for the code snippet */
  }

  .social-icons {
    margin-top: 40px;
    margin-bottom: 20px;
  }
}