.hamburger-menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 60%;
  max-width: 300px;
  background-color: #252526;
  box-shadow: 5px 0 15px rgba(0, 0, 0, 0.5);
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  z-index: 1000;
  padding-top: 60px;
  padding-left: 20px;
}

/* Open state for the menu */
.hamburger-menu.open {
  transform: translateX(0);
}

/* Menu links */
.hamburger-menu a {
  display: block;
  color: #d4d4d4;
  padding: 15px 0;
  text-decoration: none;
  font-size: 1.2em;
}

.hamburger-menu a:hover {
  color: #569cd6;
}

/* Close icon styling */
.close-icon {
  font-size: 1.5em;
  color: #d4d4d4;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
}

/* Close icon styling with border */
.close-icon {
  font-size: 1.5em;
  color: #d4d4d4;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 5px;
  border: 2px solid #d4d4d4; /* Adds border */
  border-radius: 20%;         /* Rounds the border for a circular look */
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-icon:hover {
  background-color: #333; /* Adds a background color on hover */
  color: #fff;
}
