:root {
  /* Color Variables */
  --main-theme-color: #4493f8; /* Customize as needed */
  --interaction-color: #4493f8;
  --header-footer-bar-color: #252526;
  --hover-theme-color: #464646;
  --main-bg-color: #1e1e1e;
  --lighten-hover-color: #c7c7c7;
  --text-color: #d4d4d4;
  --box-shadow: 0px 8px 16px rgba(0, 0, 0, .5);
  --box-margin: 0 auto;
  --box-padding: 30px;

    /* Global Font Sizes */
    --font-size-h1: clamp(2rem, 6vw, 2.5rem);  
    --font-size-h2: clamp(1.8rem, 4.5vw, 2rem); 
    --font-size-h3: clamp(1.3rem, 3.5vw, 1.6rem); 
    --font-size-h4: clamp(1rem, 2.8vw, 1.3rem);  
    --font-size-p: clamp(1rem, 2vw, 1.2rem);
    --font-size-pre: clamp(0.9rem, 2vw, 1.05rem);
  
}

/* Global Styles */
body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Ensures padding/border are included in width */
  width: 100%;            /* Forces the body to take full width */
  height: 100%;           /* Make sure the body takes full height */
  background-color: #181818;
  overflow-x: hidden;     /* Prevent horizontal overflow */
  overflow-y: auto;       /* Allow vertical scrolling */
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 1200px;
  width: 100%;            /* Ensure the container takes full width */
  margin: 0 auto;
  font-family: 'Fira Code', monospace;
  background-color: var(--main-bg-color);
  color: var(--text-color);
}

.main-content {
  flex: 1;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  overflow-x: hidden;     /* Prevent horizontal overflow inside main content */
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .app-container {
    padding: 0 10px; /* Add padding for smaller screens */
  }

  .main-content {
    padding: 15px; /* Adjust padding for smaller screens */
  }
}

@media (max-width: 480px) {
  .main-content {
    font-size: 0.9rem; /* Adjust font size for smaller screens */
  }
}

/* Prevent text and images from overflowing */
h1, h2, h3, p, img {
  word-wrap: break-word; /* Ensure long words break */
  max-width: 100%; /* Ensure images are responsive */
}

/* Optional: Prevent overflow on specific elements */
.scrollable-content {
  overflow-x: auto;
  white-space: nowrap;
}
