/* Basic Layout and Styling */
.about-page {
  max-width: 900px;
  margin: var(--box-margin);
  padding: 0 30px 30px 30px;
  box-shadow: var(--box-shadow);
  background-color: var(--main-bg-color);
  color: #ffffff;
  border-radius: 10px;
  font-family: 'Fira Code', monospace;
  box-sizing: border-box;
}

/* Heading Styling */
.about-container h2 {
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
  font-size: var(--font-size-h2);
}

.about-container h3 {
  font-size: var(--font-size-h3);
  /* Responsive font size */
  color: #ffffff;
  margin-top: 30px;
  padding-bottom: 10px;
}

/* Paragraph Styling */
.about-page p {
  line-height: 1.6;
  color: #cccccc;
  font-size: var(--font-size-p);
  /* Clamp added for responsive text */
  margin-bottom: 15px;
}

/* List Styling */
.about-container ul {
  list-style-type: none;
  padding-left: 0;
}

.about-container ul li {
  margin-bottom: 8px;
  font-size: clamp(.95rem, 2.5vw, 1.1rem);
  /* Adjusted font size for better scaling */
}

/* Links Styling */
.about-container a {
  color: var(--interaction-color);
  text-decoration: none;
}

.about-container a:hover {
  color: var(--lighten-hover-color);
}

.quotes-section {
  padding: 40px;
  margin: 0 auto;
  font-family: 'Arial', sans-serif;
}

/* Individual quote block */
.quote {
  background-color: var(--header-footer-bar-color);
  padding: 20px;
  margin-bottom: 20px;
  border-left: 5px solid var(--main-theme-color);
  /* Blue left border */
  border-radius: 8px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
}

/* Styling for the double quotes */
.quote-symbol {
  font-size: clamp(3rem, 6vw, 4rem);
  color: var(--main-theme-color);
  margin-right: 10px;
}

/* Quote text styling */
.quote-text {
  font-style: italic;
  font-size: clamp(1.1rem, 3vw, 1.4rem) !important;
  color: #555;
  line-height: 1.5;
  margin: 0 !important;
  flex-grow: 1;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .about-page {
    padding: 20px;
  }

  .quotes-section {
    padding: 20px;
  }
}

@media (max-width: 480px) {
  .about-page {
    padding: 15px;
  }
}