:root {
  --main-theme-color: #4a90e2;
  --hover-theme-color: #3a78c2;
  --main-bg-color: #1e1e1e;
  --text-color: #d4d4d4;
  --box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.5);
}

.gallery-container {
  max-width: 900px;
  margin: var(--box-margin);
  padding: 0 30px 30px 30px;
  background-color: var(--main-bg-color);
  color: #ffffff;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  font-family: 'Fira Code', monospace;
  box-sizing: border-box; /* Ensure padding and border are included in width calculations */
  color: var(--text-color);
}

.filter-container {
  display: flex;
  flex-wrap: wrap; /* Allow buttons to wrap to the next line */
  justify-content: center; /* Center the buttons */
  gap: 10px; /* Space between buttons */
  padding: 30px 0;
}

.filter-button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background-color: #3a3a3a;
  color: white;
  transition: background-color 0.3s ease;
  white-space: nowrap; /* Prevent text from wrapping */
}

.filter-button:hover {
  background-color: var(--hover-theme-color);
  color: white;
}

.filter-button.active {
  background-color: var(--main-theme-color);
  color: white;
}

.grid {
  display: flex; /* Use flexbox for row layout */
  flex-wrap: wrap; /* Wrap items to the next row if needed */
  gap: 15px; /* Space between items */
  justify-content: center; /* Center-align items */
}

.image-box {
  width: 200px; /* Fixed width for each box */
  height: 200px; /* Fixed height for uniform sizing */
  overflow: hidden;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  transition: transform 0.3s ease;
}

.image-box:hover {
  transform: scale(1.05);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1000;
  transition: opacity 0.5s ease;
}

.modal-image {
  max-width: 90%;
  max-height: 70%;
  border-radius: 8px;
}

.close-button,
.next-button,
.prev-button {
  position: absolute;
  background: none;
  color: white;
  border: none;
  font-size: 2rem;
  cursor: pointer;
}

.close-button {
  top: 20px;
  right: 20px;
}

.next-button {
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.prev-button {
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.caption {
  margin-top: 20px;
  color: white;
  font-size: 1.2rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .grid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}

@media (max-width: 480px) {
  .modal-image {
    max-width: 80%;
    max-height: 60%;
  }

  .close-button,
  .next-button,
  .prev-button {
    font-size: 1.5rem;
  }

  .caption {
    font-size: 1rem;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image fills the box without distortion */
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}

/* Spinner styles */
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* Lazy-loading transition */
.image {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.image.fade-in {
  opacity: 1;
}

/* Ensure spinner doesn't affect existing layout */
.image-box {
  position: relative;
  will-change: transform, opacity;
}
