.footer {
  background-color: var(--header-footer-bar-color);
  padding: 20px;
  color: #d4d4d4;
}

.bottom-footer {
  text-align: center;
  font-size: 0.9em;
}

.nav-links-bottoms {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: .5rem;
}

.nav-links-bottoms a {
  color: #d4d4d4;
  border-radius: 0.25rem;
  padding: 0.5rem 0.75rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Hover Effect */
.nav-links-bottoms a:hover {
  background-color: var(--hover-theme-color);; /* Hover background color */
  color: white; /* Change text color on hover */
}

/* Active Link Style (Optional) */
.nav-links-bottoms a.active {
  background-color: var(--main-theme-color); /* Active link color */
  color: white;
}