/* Existing styles */
.header {
  position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--header-footer-bar-color);
  padding: 10px 20px;
  top: 0;
  z-index: 1000;
}

.header-left {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Keeps hamburger on the left */
}

.logo {
  flex-grow: 1; /* Ensures logo takes up remaining space */
  text-align: center; /* Centers the logo */
  font-size: 1.5em;
  padding: 0rem .75rem;
}

.hamburger {
  font-size: 1.5em;
  background: none;
  border: none;
  color: var(--text-color);
  cursor: pointer;
  display: none;
}

/* Nav Links Styles */
.nav-links {
  display: flex;
  gap: .5rem;
}

.nav-links a {
  color: var(--text-color);
  border-radius: 0.25rem;
  padding: 0.5rem 0.75rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Hover Effect */
.nav-links a:hover {
  background-color: var(--hover-theme-color); /* Hover background color */
  color: white; /* Change text color on hover */
}

/* Active Link Style (Optional) */
.nav-links a.active {
  background-color: var(--main-theme-color); /* Active link color */
  color: white;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .header {
    position: relative;
    flex-direction: row;
    justify-content: center;
  }

  .logo {
    order: 2;
    margin: 10px 0;
  }

  .nav-links {
    display: none;
  }

  .hamburger {
    order: 1;
    display: block;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .nav-links.open {
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: absolute;
    top: 60px;
    left: 20px;
    background-color: #252526;
    padding: 20px;
    border-radius: 8px;
  }
}
