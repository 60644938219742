.not-found-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  text-align: center;
  color: #ffffff;
  font-family: 'Fira Code', monospace;
  max-width: 800px;
  width: 100%;
  padding: 20px;
  margin-bottom: 100px;
  box-sizing: border-box;
}

.main-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

/* Heading and paragraph with clamp for responsive font size */
.not-found-page h1 {
  font-size: clamp(3rem, 10vw, 5rem);
  /* Responsive font size using clamp */
  color: var(--main-theme-color);
}

.not-found-page p {
  font-size: clamp(1rem, 3vw, 1.5rem);
  /* Responsive font size using clamp */
  margin-top: 20px;
  color: #b0b0b0;
}

.home-link {
  display: inline-block;
  margin-top: 30px;
  padding: 10px 20px;
  font-size: 1rem;
  color: #ffffff;
  background-color: var(--main-theme-color);
  border-radius: 5px;
  text-decoration: none;
}

.home-link:hover {
  background-color: var(--hover-theme-color);
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  .not-found-page {
    padding: 15px;
    /* Reduce padding on smaller screens */
    margin-top: 50px;
    /* Reduce top margin for better fitting */
  }

  .not-found-page h1 {
    font-size: clamp(2.5rem, 8vw, 4rem);
    /* Adjust h1 font size for small screens */
  }

  .not-found-page p {
    font-size: clamp(1rem, 4vw, 1.3rem);
    /* Adjust p font size for small screens */
  }

  .home-link {
    font-size: 0.9rem;
    /* Adjust link font size for mobile */
  }
}